import React, { Component } from 'react';
import './CalendarItem.css';
import '../App.css';
import PreloadImage from 'react-preload-image'

class CalendarItem extends Component {
    constructor(props) {
        super(props);

        this.addWidth = this.props.width === 3 ? '64px' : '32px';
        this.addHeight = this.props.height === 1 ? '0px' : (this.props.height === 2 ? '32px' : '64px');

        this.state = {
            active: this.props.activeCalendarIndex === this.props.index,
            width: 'calc(' + this.props.width + ' * 100% + ' + this.addWidth + ')',
            height: 'calc(' + this.props.height + ' * 100% + ' + this.addHeight + ')',
            startHorizontal: this.props.startHorizontal
        };
        this.toggleClass = this.toggleClass.bind(this);
    }

    toggleClass() {
        const newState = !this.state.active;
        this.setState({ active: newState });

        if (newState) {
            this.props.onOpen(this.props.index);
        } else {
            this.props.onOpen(-1);
        }
    };

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        // Calculate based on grid border width
        if (window.innerWidth > 1240) {
            this.addWidth = this.props.width === 3 ? '64px' : '32px';
            this.addHeight = this.props.height === 1 ? '0px' : (this.props.height === 2 ? '32px' : '64px');

            const width = 'calc(' + this.props.width + ' * 100% + ' + this.addWidth + ')';
            const height = 'calc(' + this.props.height + ' * 100% + ' + this.addHeight + ')';
            this.setState({ width: width, height: height });
        } else {
            this.addWidth = this.props.width === 3 ? '32px' : '16px';
            this.addHeight = this.props.height === 1 ? '0px' : (this.props.height === 2 ? '16px' : '32px');

            const width = 'calc(' + this.props.width + ' * 100% + ' + this.addWidth + ')';
            const height = 'calc(' + this.props.height + ' * 100% + ' + this.addHeight + ')';
            this.setState({ width: width, height: height });
        }

        if (window.innerWidth > 1180) {
            this.setState({ startHorizontal: this.props.startHorizontal });
        } else {
            this.setState({ startHorizontal: this.props.startHorizontal2Grid });
        }
    }

    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        const backStyle = {
            backgroundImage: 'url(' + this.props.smallImage + ')',
        };

        const overlayStyle = {
            // backgroundImage: 'url(' + this.props.overlayImage + ')',
            backgroundColor: this.props.color === 'blue' ? '#316CF5' : '#8FECE2',
            width: this.state.width,
            height: this.state.height
        };

        if (this.state.startHorizontal === 'left') {
            overlayStyle['left'] = 0;
        }
        if (this.props.startVertical === 'top') {
            overlayStyle['top'] = 0;
        }

        return (
            <div className={`calendar-item calendar-item--${ this.props.color } ${this.props.activeIndex === this.props.index ? 'active' : ''}`} onClick={this.toggleClass}>
                <div className="calendar-inner">
                    <div className="front">
                        <div className="date">{this.props.number}</div>
                    </div>
                    <div className="back">
                        <PreloadImage
                            className="lazy-image"
                            src={ this.props.smallImage }
                            lazy
                        />
                        <div className="back-wrapper">
                            <div className="small-text">{this.props.smallText}</div>
                            <div className="small-attribute">– {this.props.smallAttribute}</div>
                        </div>
                    </div>
                </div>
                <div
                    className={this.props.activeIndex === this.props.index ? 'overlay active': 'overlay'}
                    style={overlayStyle}
                    onClick={this.toggleClass}>
                    <PreloadImage
                        className="lazy-image"
                        src={ this.props.overlayImage }
                        lazy
                    />
                    <div className="overlay-wrapper">
                        <div className="close"></div>
                        <div className="overlay-text" dangerouslySetInnerHTML={{__html: this.props.overlayText}}></div>
                        <div className="overlay-attribute">– {this.props.overlayAttribute}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarItem;