import React, { Component } from 'react';
import logo from '../assets/logo.png';
import './Footer.css';
import '../App.css';

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <div className="inner">
                    <a href="https://www.enso.co/" className="footer-link">
                        <img src={logo} className="logo" alt="logo" />
                        <div className="credit">made by enso</div>
                    </a>
                </div>
            </div>
        );
    }
}

export default Footer;