import React, { Component } from 'react';
import './Header.css';
import '../App.css';

class Header extends Component {

    render() {
        return (
            <div className="header">
                <div className="inner">
                    <h1>Home for the Holidays advent calendar</h1>
                    <h2>This year we’re taking you home for the holidays. Behind each window of this calendar, there is a little bit about the culture of the people at enso.</h2>
                    <h3>Happy Holidays!</h3>
                </div>
            </div>
        );
    }
}

export default Header;