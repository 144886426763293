import React, { Component } from 'react';
import './App.css';
import Header from './Header/Header';
import CalendarItem from './CalendarItem/CalendarItem';
import Footer from './Footer/Footer';
import test from './assets/test.jpg';
import test2 from './assets/test2.jpg';

import tile1 from './assets/tile1.jpg';
import tile2 from './assets/tile2.jpg';
import tile3 from './assets/tile3.jpg';
import tile4 from './assets/tile4.jpg';
import tile5 from './assets/tile5.jpg';
import tile6 from './assets/tile6.jpg';
import tile7 from './assets/tile7.jpg';
import tile8 from './assets/tile8.jpg';
import tile9 from './assets/tile9.jpg';
import tile10 from './assets/tile10.jpg';
import tile11 from './assets/tile11.jpg';
import tile12 from './assets/tile12.jpg';

import overlay1 from './assets/overlay1.jpg';
import overlay2 from './assets/overlay2.jpg';
import overlay3 from './assets/overlay3.jpg';
import overlay4 from './assets/overlay4.jpg';
import overlay5 from './assets/overlay5.jpg';
import overlay6 from './assets/overlay6.jpg';
import overlay7 from './assets/overlay7.jpg';
import overlay8 from './assets/overlay8.jpg';
import overlay9 from './assets/overlay9.jpg';
import overlay10 from './assets/overlay10.jpg';
import overlay11 from './assets/overlay11.jpg';
import overlay12 from './assets/overlay12.jpg';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCalendarIndex: -1
        };

        this.handleCalendarOverlay = this.handleCalendarOverlay.bind(this);
    }

    handleCalendarOverlay(index) {
        this.setState({activeCalendarIndex: index});
    }

  render() {
    const calendar = [
        { number: 12, color: 'blue', smallImage: tile12, smallText: 'music named after my abuelo', smallAttribute: 'Laura', height: 2, width: 3, startHorizontal: 'left', startHorizontal2Grid: 'left', startVertical: 'top', overlayImage: overlay12, overlayText: 'I named my music after my <a href="https://open.spotify.com/artist/0Em92SxV1qYXMsOHJqVsbY?si=cAkivuSLTryd4CfZ21ZBmg" target="_blank">abuelo</a>, Victor,  the most curious & creative person I know.  Growing up around the world meant that home wasn\'t always the place you were but the people you carried with you.', overlayAttribute: 'Laura Proenza'},
        { number: 6, color: 'green', smallImage: tile6, smallText: 'filling stockings', smallAttribute: 'Lauren', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'right', startVertical: 'top', overlayImage: overlay6, overlayText: 'Spending time with my mom to fill our family stocking stuffers with personalized and <a href="https://medium.com/collide/upcycled-gift-guide-5a42918570f8" target="_blank">thoughtful ideas as gifts</a>.', overlayAttribute: 'Lauren Batcheck'},
        { number: 2, color: 'blue', smallImage: tile2, smallText: 'inhotim museum', smallAttribute: 'Tiago', height: 3, width: 2, startHorizontal: 'right', startHorizontal2Grid: 'left', startVertical: 'top', overlayImage: overlay2, overlayText: '<a href="https://www.instagram.com/explore/tags/inhotim/" target="_blank">INHOTIM</a>, an open-air<br>museum in Brazil<br>where people come<br>together to be<br>inspired by nature and<br>art. It makes my<br>brain feel at home,<br>and makes me<br>proud of where I<br>come from.', overlayAttribute: 'Tiago Pereira'},
        { number: 8, color: 'green', smallImage: tile8, smallText: 'Watson, my dog!', smallAttribute: 'Michelle M.', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'right', startVertical: 'top', overlayImage: overlay8, overlayText: 'Looking into his <a href="https://www.ensoholiday.co/watson.gif" target="_blank">big fluffy face</a> instantly calms me, and I can feel the love between us. He comes to enso every day!', overlayAttribute: 'Michelle Murphy'},
        { number: 10, color: 'blue', smallImage: tile10, smallText: 'huntington library and gardens', smallAttribute: 'Ilana', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'left', startVertical: 'top', overlayImage: overlay10, overlayText: 'Ever since I was a child, visiting one particular resident of the <a href="https://www.huntington.org/" target="_blank">Huntington Library and Gardens</a>, Thomas Gainsborough’s “Blue Boy,” makes me feel inexplicable joy and contentment.', overlayAttribute: 'Ilana Horwitz'},
        { number: 7, color: 'green', smallImage: tile7, smallText: 'hugging my sons', smallAttribute: 'Kirk', height: 1, width: 2, startHorizontal: 'right', startHorizontal2Grid: 'right', startVertical: 'top', overlayImage: overlay7, overlayText: '<a href="https://youtu.be/zG-BWw__yos" target="_blank">Hugging</a> my sons and telling them I love them.', overlayAttribute: 'Kirk Souder'},
        { number: 5, color: 'blue', smallImage: tile5, smallText: 'hiking mountain peaks', smallAttribute: 'Justin', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'left', startVertical: 'top', overlayImage: overlay5, overlayText: 'Hiking the <a href="https://socalhiker.net/a-six-pack-of-peaks/" target="_blank">tallest peaks of Southern California</a>. Meditating on top and then opening your eyes to pure beauty is something out of this world.', overlayAttribute: 'Justin Mersinger'},
        { number: 9, color: 'green', smallImage: tile9, smallText: 'Istanbul\'s city smells', smallAttribute: 'Berna', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'right', startVertical: 'top', overlayImage: overlay9, overlayText: 'City smells and freshly baked bread remind me of being in <a href="https://www.instagram.com/turkey_home/" target="_blank">Istanbul</a> with my grandparents.', overlayAttribute: 'Berna Dikicioglu'},
        { number: 3, color: 'blue', smallImage: tile3, smallText: 'orca whales', smallAttribute: 'Tynessa', height: 2, width: 2, startHorizontal: 'right', startHorizontal2Grid: 'left', startVertical: 'top', overlayImage: overlay3, overlayText: 'Seeing marine life of <a href="https://www.vanaqua.org/research" target="_blank">Pacific North West</a> in the wild is a treasure that may not last forever.', overlayAttribute: 'Tynessa Jue'},
        { number: 1, color: 'green', smallImage: tile1, smallText: 'korean food and palm trees', smallAttribute: 'Sarah', height: 1, width: 3, startHorizontal: 'left', startHorizontal2Grid: 'right', startVertical: 'bottom', overlayImage: overlay1, overlayText: 'Born and raised in LA, palm trees and my mom\'s homecooked <a href="https://www.google.com/maps/d/u/0/viewer?ll=34.06347102064722%2C-118.29900329999998&hl=en&hl=en&z=18&mid=1r85_wsUkhge8H-CjSoh5q6Ad24g7E7NJ" target="_blank">Korean food</a> reminds me I’m home.', overlayAttribute: 'Sarah Chung'},
        { number: 4, color: 'blue', smallImage: tile4, smallText: 'designing clothes', smallAttribute: 'Will', height: 2, width: 2, startHorizontal: 'left', startHorizontal2Grid: 'left', startVertical: 'bottom', overlayImage: overlay4, overlayText: 'Fashion as a medium to study my cultural past, guide my future, and hopefully inspire others to embark on similar journey of <a href="https://shopukoostudios.com/" target="_blank">self-learning</a>.', overlayAttribute: 'Will Edem'},
        { number: 11, color: 'green', smallImage: tile11, smallText: 'my family recipe', smallAttribute: 'Michelle S.', height: 2, width: 2, startHorizontal: 'right', startHorizontal2Grid: 'right', startVertical: 'bottom', overlayImage: overlay11, overlayText: 'My family\'s famous <a href="https://toriavey.com/toris-kitchen/mamans-moroccan-fish/" target="_blank">Spicy Moroccan Salmon</a> - that first bite instantly takes me back to being in the kitchen with them!', overlayAttribute: 'Michelle Stewart'}
    ];
      const self = this; // save object reference

    return (
      <div className="App">
        <header className="App-header">
          <Header/>
        </header>
        <div className="App-Calendar">

            {calendar.map(function(item, index) {
                return <CalendarItem
                    key={ index }
                    index={ index}
                    onOpen={ self.handleCalendarOverlay }
                    activeIndex = { self.state.activeCalendarIndex }
                    number={ item.number }
                    color={ item.color }
                    smallImage={ item.smallImage }
                    smallText={ item.smallText }
                    smallAttribute={ item.smallAttribute }
                    height={ item.height }
                    width={ item.width }
                    startHorizontal={ item.startHorizontal }
                    startHorizontal2Grid={ item.startHorizontal2Grid }
                    startVertical={ item.startVertical}
                    overlayImage={ item.overlayImage }
                    overlayText={ item.overlayText }
                    overlayAttribute={ item.overlayAttribute }
                />;
            })}
        </div>
        <footer className="App-footer">
            <Footer/>
        </footer>
      </div>
    );
  }
}

export default App;
